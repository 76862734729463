<template>

    <div class="main" :style="mainStyle" id="mainView">
        <iframe v-show="true" ref="gameIframe" :src="gameUrl" frameborder="no" border="0"  scrolling="no" width="100%" height="100%"></iframe>
        <div class="qili-icon" id="moveDiv"
             @mousedown="down($event)"
             @touchstart="down($event)"
             @mousemove="move($event)"
             @touchmove="move($event)"
             @mouseover="end($event)"
             @mouseleave="move($event)"
             @mouseup="end($event)"
             @touchend="end($event)">

            <img src="../../static/flow/all.png" v-if="redIcon === 1"  width="60px" height="75.77px" @click="openMain">
            <img src="../../static/flow/half_cap.png" v-if="redIcon === 2"  width="38px" height="72.29px" @click="showAllIcon">
            <img src="../../static/flow/half.gif" v-if="redIcon === 3" width="38px" height="72.29px" @click="showAllIcon">
        </div>

        <!-- color="#FFD284" -->

        <van-notice-bar  v-if="showNotice" delay="0"  :style="noticeStyle" style="color: #eca007;pointer-events: none;position: absolute;top: 100px;left: 50%; transform: translate(-50%, -50%); font-size: 14px;width: 100%;max-width: 450px; height: 35px;background-color: rgba(0,0,0,.4)"
                         speed="30"  :text="noticeMessage" @replay="hideNotice"/>

        <van-popup v-model="showMainDialog" round position="bottom" :style="{ height: '45%' }" style="background: transparent">
            <!-- 弹出框主页 -->
            <div v-if="page === 0" class="main-dialog-view" >
                <img @click="showMainDialog = false" src="../../static/close_round.png" style="position: absolute;right: -2px;top: 0;" width="30px" height="30px">
                <div style="display: flex;justify-content: space-between">
                    <div style="display: flex;padding: 0 0 0 5px;color: rgb(255 134 0);align-items: center">
                        <img v-if="!!userInfo.headerImg" :src="userInfo.headerImg" width="30px" height="30px" style="border-radius: 5px;">
                        <img v-else src="../../static/game-logo.png" width="30px" height="30px">
                        <div style="display: flex;flex-direction: column;align-items: flex-start;font-size: 12px;margin-left: 10px;transform: scale(.8);transform-origin: left;">
                                <span>{{userInfo.userName}}   <van-icon name="balance-o" /> {{qlb}}</span>
                                <span>ID {{userInfo.userId}}</span>
                                <span>{{userInfo.phone}}</span>
                        </div>
                    </div>

                    <img src="../../static/main_page/menu_change.png" width="133px" height="35px" @click="goPasswordPage">
                </div>

                <div style="padding-left: 40px;text-align: left;height: 20px;display: flex;align-items: center">
                    <img v-if="!!userInfo.phone" src="../../static/main_page/button_1.png" width="50px" height="20px" @click="goUnBindPage">
                    <img v-if="!userInfo.phone" src="../../static/main_page/button_2.png" width="50px" height="20px" @click="goBindPage">
                </div>

                <!--<div @click="openScDialog" style="width: 15px;position: absolute;left: 0;top: 30%; font-size: 12px;color:#FFFFFF;background-color: rgb(176 130 69);padding: 3px 5px;border-top-right-radius: 3px;border-bottom-right-radius: 3px;">
                    素材有奖征集
                </div>-->

                <!--<div @click="openGbDialog" style="width: 15px;position: absolute;right: 0;top: 30%; font-size: 12px;color:#FFFFFF;background-color: rgb(135 16 232);padding: 3px 5px;border-top-left-radius: 3px;border-bottom-left-radius: 3px;">
                    发送全服广播
                </div>-->

                <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 5px;padding: 0 40px;">
                    <div style="display: flex;flex-direction: column">
<!--                        <img  @click="toRedPage" :class="redMenuRemind && 'red-remind'" class="main-menu" src="../../static/main_page/menu_red.png">-->
                        <img  @click="toGiftDetailPage" class="main-menu" src="../../static/main_page/menu_get.png">
                        <img @click="toBingWxPage" class="main-menu" src="../../static/main_page/menu_bingwx.png" style="">
                    </div>
                    <div style="padding-top: 5px;box-sizing: border-box; height: 100%; display: flex;flex-direction: column;align-items: center;justify-content: space-between; position: relative">
                        <span v-if="false" @click="openTradeDialog" style="position: absolute;top: -30px;; width: 60px; ;font-size: 13px;color: rgb(234,153,18);background-color: rgba(234,153,18,0.29);padding: 3px 10px;border-radius: 3px;font-weight: bold;margin-bottom: 10px;">交易平台</span>
                        <div @click="openGbDialog" style="width: 60px; font-size: 12px;color:#ffffff;background-color: #db5e23;padding: 5px 10px;border-radius: 3px;">
                            发送喇叭
                        </div>
                        <div  @click="toSpeedPage" style="font-size: 12px;color:#ffffff;background-color: #c44e4e;padding: 5px 10px;border-radius: 3px;">
                            开启加速
                        </div>
                        <img @click="logout" src="../../static/main_page/logout.png" style="width: 70px;height: 30px;">
                    </div>
                    <div style="display: flex;flex-direction: column">

                        <img @click="toMhPage" class="main-menu" src="../../static/main_page/menu_mh.png">
                        <img @click="toKfPage" class="main-menu" src="../../static/main_page/menu_kf.png">
                    </div>
                </div>



                <div v-if="false" style="display: flex;align-items: center;padding: 0 40px;">
                    <div class="back-input">
                        <van-field placeholder="请输入七里平台福利码" @focus="inputFocus" @blur="inputBlur" style="background: transparent;color: #FFFFFF;border: none;height: 38px;"
                               v-model="welfareCode" clearable/>
                    </div>
                    <img @click="welfareCodeSubmit" style="width: 90px;height: 40px" src="../../static/main_page/dh.png">
                </div>

                <div style="display: flex;align-items: center;padding: 0 45px;justify-content: space-between;margin-top: 7px">
                    <div @click="openTradeDialog" style="width: 50px;font-weight: bold; font-size: 11px;color:#f1dcdc;background-color: #ee5656;padding: 5px 10px;border-radius:5px;">
                        交易平台
                    </div>

                    <div v-if="false" @click="toGcGamePage" style="width: 50px;font-weight: bold; font-size: 11px;color:#f1dcdc;background-color: #ee5656;padding: 5px 10px;border-radius:5px;">
                        小游戏
                    </div>

                    <div @click="openRecharge" style="width: 50px;font-weight: bold; font-size: 11px;color:#f1dcdc;background-color: #ee5656;padding: 5px 10px;border-radius:5px;">
                        充值
                    </div>
                </div>

                <div class="bottom-view">
                    <img src="../../static/main_page/right.png" style="width: 25px;height: 15px;margin-top: 3px;">
                    <span style="color: #589BEF;font-size: 14px;flex: 1;">{{bottomMsg}}</span>
                </div>

            </div>

            <!-- 红包列表 -->
            <div v-if="page === 1" class="body-view redlist-body">
                <div  class="header-view redlist-top">
                    <div @click="backMain" class="left-view">
                        <img src="../../static/redlist/left.png" style="width: 10px;height: 20px;">
                    </div>
                    <span>抢福利红包</span>
                </div>
                <div style="flex: 1; overflow: hidden;display: flex;flex-direction: column; align-items: center;justify-content: center;padding: 5px 10px 10px">
                    <div class="redlist-content">
                        <div style="color: #FFFFFF;text-align: left;display: flex;flex-direction: column; align-items: flex-start">
                            <span>领红包</span>
                            <div style="margin-top: 5px;">
                                <span style="font-size: 12px;">绑定微信后才能领取红包，超时未领取的红包不予补发</span>
                            </div>

                        </div>
                        <div style="margin-top: 10px;" v-for="item in redDataList" v-if="redDataList.length > 0">
                            <div @click="openRedpack(item)" style="position: relative;" :style="item.status !== 1 ? 'opacity: .7' : ''">
                                <img src="../../static/redlist/redpack.png" style="width: 217px;height: 74px;">
                                <span style="position: absolute;bottom: 15px;left: 15px;color: #EEEEEE;font-size: 12px;">{{item.redMsg}}</span>
                            </div>
                        </div>

                        <div style="color: #DDDDDD;font-size: 16px;margin-top: 50px;text-align: center"  v-if="redDataList.length === 0">
                            <span>暂时还没有红包</span>
                            <div style="font-size: 12px;margin-top: 10px;">每周二、周六19:00-21:00随机时间点系统发放福利红包</div>
                        </div>
                    </div>
                    <img @click="toRedRecordPage" src="../../static/redlist/title.png" style="width: 80px;height: 30px;position: absolute;right: 0;top: 60px;">
                </div>
            </div>

            <!-- 绑定微信 -->
            <div v-if="page === 3" class="body-view redlist-body">
                <div  class="header-view redlist-top">
                    <div @click="backMain" class="left-view">
                        <img src="../../static/redlist/left.png" style="width: 10px;height: 20px;">
                    </div>
                    <span>绑定微信</span>
                </div>
                <div style="flex: 1; overflow: hidden;display: flex;flex-direction: column; align-items: center;justify-content: center;padding: 5px 10px 10px">
                    <div class="redlist-content" style="overflow: hidden">
                        <div v-if="isBindWx === 0">
                            <div style="flex-direction: row;margin-top: 15px;align-items: flex-end;">
                                <span style="color: #FDE34C;font-size: 18px;">绑定码：</span>
                                <span style="color: #FDE34C;font-size: 22px;margin-left: 10px;">{{bindWxCode}}</span>
                            </div>
                            <p style="color: #BFDBFC;font-size: 12px;margin-top: 10px;">提示：绑定码已复制到剪贴板</p>
                            <p style="color: #BFDBFC;margin-top: 15px;font-size: 13px;">进入微信后，搜索公众号：七里网络，点击下方菜单活动福利选择绑定账号，进入界面之后输入绑定码进行绑定 </p>
                            <img src="../../static/go_follow.png" style="width: 100px;height: 36px;margin-top: 15px" @click="toWxForBind">
                        </div>

                        <div v-if="isBindWx === 1">
                            <div style="flex-direction: row;margin-top: 15px;align-items: flex-end;">
                                <span style="color: #FDE34C;font-size: 20px;">{{bindWxCode}}</span>
                            </div>
                            <p style="color: #BFDBFC;font-size: 12px;margin-top: 10px;">您已成功绑定微信，如需解绑，请点击下方按钮</p>
                            <img src="../../static/unbindwx.png" style="width: 90px;height: 33px;margin-top: 30px" @click="unBindWx">
                        </div>

                    </div>
                </div>
            </div>

            <!-- 发送喇叭 -->
            <div v-if="page === 13" class="body-view redlist-body">

                <div style="position: absolute;font-size: 10px;top: 10px;right: 10px;display: flex;flex-direction: column;color: #ddb90b;align-items: flex-start">
                    <span style="">七里币: {{qlb}}</span>
                    <span style="">剩余喇叭: {{shoutNum}}</span>

                </div>
                <div  class="header-view redlist-top">
                    <div @click="backMain" class="left-view">
                        <img src="../../static/redlist/left.png" style="width: 10px;height: 20px;">
                    </div>
                    <span>发送广播</span>
                </div>
                <div style="flex: 1; overflow: hidden;display: flex;flex-direction: column; align-items: center;justify-content: center;padding: 5px 10px 10px">
                    <div class="redlist-content" id="msgListView" style="font-size: 12px; -webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;">
                        <div v-for="item in messageList">
                            <div v-if="item.userId !== uid" style="display: flex;margin-right: 50px;margin-top: 10px;">
                                <div style="width: 30px;height: 30px;">
                                    <img v-if="!!item.imgUrl" :src="item.imgUrl" style="width: 30px;height: 30px;border-radius: 50%;"/>
                                    <img v-else src="../../static/game-logo.png" style="width: 30px;height: 30px;border-radius: 50%;"/>
                                </div>
                                <div style="text-align: left;margin-left: 15px;">
                                    <div style="color: #cbcbcb">{{item.serverName}} {{item.roleName}}<span style="margin-left: 5px">{{item.time}}</span></div>
                                    <div style="background-color: #ffffff;padding: 10px 10px;margin-top: 5px;border-radius: 5px;word-break:break-all;word-wrap: break-word;width: fit-content">
                                        {{item.msg}}
                                    </div>
                                </div>
                            </div>

                            <div v-else style="display: flex;margin-top: 10px;justify-content: flex-end">
                                <div style="text-align: right;margin-right: 15px;margin-left: 50px;display: flex;flex-direction: column;align-items: flex-end">
                                    <div style="color: #cbcbcb"><span style="margin-right: 5px">{{item.time}}</span>我</div>
                                    <div style="background-color: #66d53e;padding: 10px 10px;margin-top: 5px;text-align: left;border-radius: 5px;word-break:break-all;word-wrap: break-word;width: fit-content">
                                        {{item.msg}}
                                    </div>
                                </div>
                                <div style="width: 30px;height: 30px;">
                                    <img v-if="!!item.imgUrl" :src="item.imgUrl" style="width: 30px;height: 30px;border-radius: 50%;"/>
                                    <img v-else src="../../static/game-logo.png" style="width: 30px;height: 30px;border-radius: 50%;"/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="message-input-view" style="display: flex;padding: 0 15px;font-size: 12px;align-items: center;margin-bottom: 5px;justify-content: space-between">
                    <van-field maxlength="50" @focus="showEmoji = false" ref="inputText" id="inputText" @blur="campaignNameBlur" placeholder="每次发送消耗2七里币" class="message-input" v-model="gbMessage" clearable >
                        <template #button>
                            <span style="color: rgba(207,169,114,0.44);font-size: 10px;">{{gbMessage.length}}/50</span>
                        </template>
                    </van-field>
                    <div @click="showEmoji = !showEmoji" style="font-size: 25px;margin-left: 5px;margin-right: 5px;">
                        <span style="">😀</span>
                    </div>

                    <div @click="sendGbNew" style="font-size: 13px;border: 1px solid rgb(234,153,18); color: rgb(234,153,18);background-color: rgba(234,153,18,0.29);padding: 0 20px;border-radius: 3px;font-weight: bold;height: 30px;line-height: 30px;">发送</div>

                </div>

            </div>

            <!-- 盲盒 -->
            <div v-if="page === 4" class="body-view mh-body">
                <div  class="header-view mh-top">
                    <div @click="backMain" class="left-view">
                        <img src="../../static/draw/left.png" style="width: 10px;height: 20px;">
                    </div>
                    <span>七里盲盒</span>
                </div>
                <van-notice-bar  v-if="mhNotice !== ''" scrollable color="#FFD284"  style="font-size: 12px; height: 28px;background-color: rgba(0,0,0,.4)"
                        left-icon="volume-o"
                        :text="mhNotice"/>
                <div style="flex: 1; text-align: center;padding: 0 5%;">
                    <div style="position: relative;width: 120px;height: 40px;margin: 15px auto 0">
                        <img src="../../static/draw/button.png" style="width: 120px;height: 40px;">
                        <span style="position: absolute;top: 5px;left: 10px;color: #FFD284;">剩余盲盒: {{mhCount}}</span>
                    </div>
                    <img v-if="openHzStep === 1" @click="openHz" src="../../static/draw/hz.png" style="width: 122px;height: 120px;">
                    <img v-if="openHzStep === 2" @click="showAllIcon" src="../../static/draw/mh2.gif" style="width: 110px;height: 110px;margin-top: 3px;">
                    <img v-if="openHzStep === 3" @click="showAllIcon" src="../../static/draw/mh_open.png" style="width: 150px;height: 150px;margin-top: -10px;">
                </div>

                <img @click="toMhRecordPage" src="../../static/draw/detail.png" style="width: 80px;height: 30px;position: absolute;right: 0;top: 80px;">
                <div style="width: 75px;height: 25px;position: absolute;right: 0px;top: 120px;background-color: rgba(0,0,0,.2);display: flex;justify-content: space-around;border-bottom-left-radius: 3px;border-top-left-radius: 3px;align-items: center">
                    <img @click="showMhInfo" src="../../static/what.png" style="width: 20px;height: 20px;">
                    <img @click="showDjList" src="../../static/dj.png" style="width: 20px;height: 20px;">
                </div>


            </div>

            <!-- 客服 -->
            <div v-if="page === 5" class="body-view redlist-body">
                <div  class="header-view redlist-top">
                    <div @click="backMain" class="left-view">
                        <img src="../../static/redlist/left.png" style="width: 10px;height: 20px;">
                    </div>
                    <span>在线客服</span>
                </div>
                <div style="flex: 1; overflow-y: auto;text-align: left;padding: 0 5% 10px;">
                    <div style="display: flex;align-items: flex-end;margin-top: 10px;">
                        <span style="color: #FCCD6A;">QQ客服</span>
                        <div style="color: #EEEEEE;font-size: 12px;transform: scale(.8);transform-origin: left;padding-left: 15px;">
                            <span>提示：点击后可直接进入咨询页面</span>
                        </div>
                    </div>

                    <div @click="toQQKf(item.linkUrl)" class="redlist-record-view" v-for="item in QQList" v-if="true" style="width: 75%">
                        <div style="width: 100%; display: flex;flex-direction: row; color: #FCCD6A;font-size: 13px;align-items: center;">
                            <span>{{item.linkUrl}}</span>
                            <span style="color: #FFFFFF;margin-left: 25px;">{{item.name}}</span>
                        </div>
                        <span style="color: #FFFFFF;font-size: 13px;margin-top: 1px;">{{item.workTime}}</span>
                    </div>

                    <span style="color: #FCCD6A;margin-top: 10px;display: block">微信客服</span>
                    <div @click="toWxKf(item.linkUrl)" class="redlist-record-view" v-for="item in wxList" v-if="true" style="width: 75%">
                        <div style="width: 100%; display: flex;flex-direction: row; color: #FCCD6A;font-size: 13px;align-items: center;">
                            <span>七里网络微信客服</span>
                            <span style="color: #FFFFFF;margin-left: 25px;">{{item.name}}</span>
                        </div>
                        <span style="color: #FFFFFF;font-size: 13px;margin-top: 1px;">{{item.workTime}}</span>
                    </div>
                    <div style="color: #DDDDDD;font-size: 16px;margin-top: 50px;text-align: center"  v-if="false">
                        <span>暂无盲盒开启记录</span>
                    </div>
                </div>
            </div>

            <!-- 加速 -->
            <div v-if="page === 6" class="body-view redlist-body">
                <div  class="header-view redlist-top">
                    <div @click="backMain" class="left-view">
                        <img src="../../static/redlist/left.png" style="width: 10px;height: 20px;">
                    </div>
                    <span>开启加速器</span>
                </div>
                <div style="flex: 1; overflow: hidden;display: flex;flex-direction: column; align-items: center;justify-content: center;padding: 5px 10px 10px">
                    <div class="redlist-content" style="overflow: hidden">
                        <div>
                            <div style="display: flex;justify-content: space-between;margin-top: 40px;">
                                <div @click="setSpeed(0.5)" class="speed-button" :style="speedRate === 0.5 ? 'background: #F05858;font-weight: bold' : ''">
                                    0.5倍
                                </div>
                                <div @click="setSpeed(1)" class="speed-button" :style="speedRate === 1 ? 'background: #F05858;font-weight: bold' : ''">
                                    原速
                                </div>
                                <div @click="setSpeed(1.5)" class="speed-button" :style="speedRate === 1.5 ? 'background: #F05858;font-weight: bold' : ''">
                                    1.5倍
                                </div>
                                <div @click="setSpeed(3)" class="speed-button" :style="speedRate === 3 ? 'background: #F05858;font-weight: bold' : ''">
                                    3倍
                                </div>
                                <div @click="setSpeed(5)" class="speed-button" :style="speedRate === 5 ? 'background: #F05858;font-weight: bold' : ''">
                                    5倍
                                </div>
                                <div @click="setSpeed(10)" class="speed-button" :style="speedRate === 10 ? 'background: #F05858;font-weight: bold' : ''">
                                    10倍
                                </div>
                                <div @click="setSpeed(16)" class="speed-button" :style="speedRate === 16 ? 'background: #F05858;font-weight: bold' : ''">
                                    16倍
                                </div>
                                <div @click="setSpeed(32)" class="speed-button" :style="speedRate === 32 ? 'background: #F05858;font-weight: bold' : ''">
                                    32倍
                                </div>
                            </div>
                            <div style="color: #e59e1b;font-size: 12px;margin-top: 30px;">当前游戏加速倍率：{{speedRate}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 红包记录 -->
            <div v-if="page === 11" class="body-view redlist-body">
                <div  class="header-view redlist-top">
                    <div @click="toRedPage" class="left-view">
                        <img src="../../static/redlist/left.png" style="width: 10px;height: 20px;">
                    </div>
                    <span>红包领取记录</span>
                </div>
                <div style="flex: 1; overflow-y: auto;text-align: center;padding: 0 5% 10px;">
                    <div class="redlist-record-view" v-for="item in redLogList" v-if="redLogList.length > 0">
                        <div style="width: 100%; display: flex;flex-direction: row; color: #FCCD6A;font-size: 15px;align-items: center;justify-content: space-between">
                            <div>
                                <span>领取福利红包</span>
                                <span style="margin-left: 20px;">{{item.statusName}}</span>
                            </div>
                            <span>{{item.money}}</span>
                        </div>
                        <span style="color: #FFFFFF;font-size: 13px;margin-top: 1px;">{{item.time}}</span>
                    </div>
                    <div style="color: #DDDDDD;font-size: 16px;margin-top: 50px;text-align: center"  v-if="redLogList.length === 0">
                        <span>暂无红包领取记录</span>
                    </div>
                </div>
            </div>

            <!-- 盲盒记录 -->
            <div v-if="page === 12" class="body-view redlist-body">
                <div  class="header-view redlist-top">
                    <div @click="toMhPage" class="left-view">
                        <img src="../../static/redlist/left.png" style="width: 10px;height: 20px;">
                    </div>
                    <span>盲盒开启记录</span>
                </div>
                <div style="flex: 1; overflow-y: auto;text-align: center;padding: 0 5% 10px;">
                    <div class="redlist-record-view" v-for="item in mhRecordList" v-if="mhRecordList.length > 0">
                        <div style="display: flex;flex-direction: row;width: 100%; color: #FCCD6A;align-items: center;font-size: 12px;">
                            <img :src="item.imgSrc" style="width: 35px;height: 35px;">
                            <div style="width: 100%; display: flex;flex: 1; flex-direction: column; align-items: flex-start;justify-content: center;padding-left: 15px;">
                                <span>{{item.awardName}}</span>
                                <span style="color: #FFFFFF;font-size: 12px;">{{item.gainTime}}</span>
                            </div>
                            <span>{{item.statusName}}</span>

                        </div>
                    </div>
                    <div style="color: #DDDDDD;font-size: 16px;margin-top: 50px;text-align: center"  v-if="mhRecordList.length === 0">
                        <span>暂无盲盒开启记录</span>
                    </div>
                </div>
            </div>

            <!-- 充值七里币 -->
            <div v-if="page === 14" class="body-view redlist-body">
                <div  class="header-view redlist-top">
                    <div @click="backMain" class="left-view">
                        <img src="../../static/redlist/left.png" style="width: 10px;height: 20px;">
                    </div>
                    <span>充值七里币</span>
                </div>
                <div style="overflow: hidden;display: flex;flex-direction: column; align-items: center;justify-content: center;">

                    <div style="background-color: rgba(0,0,0,0.15); width: 95%;height: 100%; margin: 10px auto;box-sizing: border-box;border-radius: 5px;padding: 10px;">
                        <div style="text-align: left;font-size: 12px;margin: 0 30px;color: #eeeeee">
                            当前余额<span style="color: #e76a0c;font-weight: bold;margin-left: 10px;">{{qlb}}</span>
                        </div>

                        <div style="width: 85%;margin: 10px auto 0">
                            <van-field maxlength="10" id="inputAmount" style="border-radius: 5px;" type="digit" placeholder="请输入充值金额" class="red-input" v-model="amount" />
                        </div>

                        <van-divider  :style="{ color: '#c4c4c4', borderColor: '#b8b9ba',margin: '10px 50px 0' }">请选择支付方式</van-divider>
                        <div style="width: 85%; box-sizing: border-box;margin: 0 auto">
                            <van-radio-group v-model="rechargePayType">
                                <div class="pay-item" @click="rechargePayType = '2'">
                                    <div style="display: flex;align-items: center">
                                        <img src="../../static/zfb.png" style="width: 20px;height: 20px;">
                                        <span style="margin-left: 10px;" >支付宝</span>
                                    </div>
                                    <van-radio name="2" shape="square" icon-size="18px"></van-radio>

                                </div>
                                <div class="pay-item" @click="rechargePayType = '3'">
                                    <div style="display: flex;align-items: center">
                                        <img src="../../static/wx.png" style="width: 20px;height: 20px;">
                                        <span style="margin-left: 10px;">微信</span>
                                    </div>
                                    <van-radio name="3" shape="square" icon-size="18px"></van-radio>
                                </div>
                            </van-radio-group>

                            <div @click="submitRecharge" style="width: 100px;font-weight: bold;margin-top: 15px; font-size: 13px;color:#f1dcdc;background-color: #ef8819;padding: 7px 10px;border-radius:5px;">
                                确认充值
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </van-popup>

        <myloading v-show="showLoading"></myloading>

        <van-dialog v-model="showCodeForUnbindWx" show-cancel-button :before-close="doUnbindWxWithCode">
            <div style="width: 100%;font-size: 14px;padding: 15px 0;">
                <div style="text-align: left;padding: 10px 30px;">{{unbindWxTitle}}</div>
                <span>验证码</span>
                <input placeholder="请输入验证码" type="number" v-model="unbindCode" style="border: 1px solid #EEEEEE;padding: 8px;font-size: 14px;margin-left: 10px;"/>
            </div>
        </van-dialog>

        <!-- 支付窗口 -->
        <van-popup v-model="showPayDialog" position="center"  style="background: transparent;width: 90%;">
            <div style="width: 100%;height: 100%;background-color: #F7F8FA;border-radius: 8px;padding: 0 15px 5px;box-sizing: border-box">
                <div style="font-size: 18px;font-weight: bold;color: #3F42B1;padding: 10px 0;">
                    {{goodsName}}
                </div>
                <div style="width: 100%;padding: 0 9px;box-sizing: border-box;background-color: #FFFFFF">
                    <div style="display: flex;justify-content: space-between;font-size: 13px;padding: 8px 0;">
                        <span>商品价格</span>
                        <span>{{orderMoney}}</span>
                    </div>
                    <div style="display: flex;justify-content: space-between;font-size: 13px;padding: 8px 0;" :style="showDiscount ? '' : 'color : #cccccc'">
                        <span>优惠折扣 / 抵扣率</span>
                        <span>{{preferenceMoney}}</span>
                    </div>
                    <div style="display: flex;justify-content: space-between;font-size: 13px;padding: 8px 0;">
                        <span>七里帐号</span>
                        <span>{{uid}}</span>
                    </div>
                </div>
                <van-divider  :style="{ color: '#3F42B1', borderColor: '#3F42B1',margin: '10px 0' }">请选择支付方式</van-divider>
                <div style="width: 100%;padding: 0 9px;box-sizing: border-box;background-color: #FFFFFF">
                    <van-radio-group v-model="payType" @change="changePayType">
                    <div style="display: flex;justify-content: space-between;font-size: 12px;padding: 8px 0;">
                        <div style="display: flex;align-items: center">
                            <img src="../../static/zfb.png" style="width: 20px;height: 20px;">
                            <span style="margin-left: 10px;" >支付宝</span>
                        </div>
                        <van-radio name="2" shape="square" icon-size="18px"></van-radio>
                    </div>
                    <div style="display: flex;justify-content: space-between;font-size: 13px;padding: 8px 0;">
                        <div style="display: flex;align-items: center">
                            <img src="../../static/wx.png" style="width: 20px;height: 20px;">
                            <span style="margin-left: 10px;">微信</span>
                        </div>
                        <van-radio name="3" shape="square" icon-size="18px"></van-radio>
                    </div>
                    <div style="display: flex;justify-content: space-between;font-size: 13px;padding: 8px 0;">
                        <div style="display: flex;align-items: center">
                            <img src="../../static/main-round.png" style="width: 20px;height: 20px;">
                            <span style="margin-left: 10px;">七里币（余额{{qlb}}）</span>
                        </div>
                        <van-radio name="1" shape="square" icon-size="18px"></van-radio>
                    </div>
                    </van-radio-group>
                </div>
                <van-button color="#5A6EFC" class="pay-button" block @click="pay">立即支付</van-button>
                <div style="color: #888888;font-size: 12px;padding: 8px 0;margin-top: 5px;">
                    <span @click="showPayDialog = false">取消支付</span>
                </div>

               <!-- <iframe v-if="payIframeShow" :src="payUrl"  width="90%" frameborder="no" border="0" marginwidth="0"
                        marginheight="0" scrolling="yes" height="1px;" style="bottom: 2px;left: 5%"></iframe>
-->
            </div>
        </van-popup>

        <div class="diy-dialog" v-show="payConfirmShow">
            <div style="text-align: center">信息</div>
            <van-divider :style="{ margin: '10px 0' }"/>
            <div style="height: 80px;padding: 0 15px;">您将打开支付页面，请问是否继续？<br><br>
                <span style="color: #b3b3b6; font-size: 13px;">注：支付成功后请关闭新打开的支付窗口</span>
            </div>
            <van-divider :style="{ margin: '10px 0 0' }"/>
            <div class="diy-dialog-buttons">
                <div style="flex: 1;text-align: center">
                    <a @click="payConfirmShow = false">取消</a>
                </div>
                <div style="height: 100%;width: 1px;background-color: #ebedf0"></div>
                <div style="flex: 1;text-align: center">
                    <a target="_blank" style="color: #1783d6" :href="payUrl" @click="payConfirmShow = false">继续</a>
                </div>

            </div>

        </div>

        <van-popup v-model="passwordShow" @closed="sendKeyboardFlag(true)" position="top" :style="{ width: '100%', height: '55%' }" >
            <passwordPage></passwordPage>
        </van-popup>
        <van-popup v-model="bindtelShow"  @closed="sendKeyboardFlag(true)" position="top" :style="{ width: '100%', height: '55%' }" >
            <bindtelPage></bindtelPage>
        </van-popup>
        <van-popup v-model="unbindtelShow"  @closed="sendKeyboardFlag(true)" position="top" :style="{ width: '100%', height: '55%' }" >
            <unbindtelPage></unbindtelPage>
        </van-popup>

        <van-popup v-model="giftdetailShow" position="top" :style="{ width: '100%', height: '80%' }" >
            <giftdetailPage ref="gitDetailRef"></giftdetailPage>
        </van-popup>

        <van-popup v-model="giftrecordShow" position="top" :style="{ width: '100%', height: '80%' }" >
            <giftrecordPage ref="gitRecordRef"></giftrecordPage>
        </van-popup>

        <van-popup v-model="redpackShow" position="top" :lock-scroll="false" :style="{ width: '100%', height: '100%' }" >
            <redpackPage ref="redpackRef"></redpackPage>
        </van-popup>
        <van-popup v-model="gcGameShow" position="top" :style="{ width: '100%', height: '100%' }" >
            <temp-game ref="gcGame"></temp-game>
        </van-popup>

        <img  v-if="showMhBackground" src="../../static/1.gif" style="opacity: .5; z-index: 2023; width: 100%;height: 100%;position: absolute;right: 0;top: 0px;">

        <van-dialog v-model="showMhResult" title="恭喜您从盲盒中获得以下奖品" :show-cancel-button="false" @confirm="closeMhResultDialog">
            <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;padding: 15px 10px">
                <img :src="openMhImg" style="width: 50px; height: 50px;"/>
                <div style="text-align: left;margin-left: 20px;">
                    <span style="">{{openMhMsg}}</span>
                </div>
            </div>
        </van-dialog>

        <van-dialog v-model="showMhDj" title="盲盒大奖记录" :show-cancel-button="false" @confirm="closeMhResultDialog">
            <div style="text-align: left;font-size: 12px;height: 200px;overflow-y: auto;margin-top: 10px;">
                <div v-for="item in mhdjList" style="display: flex;align-items: center;justify-content: flex-start;padding: 5px 10px">
                    <img :src="item.imgSrc" style="width: 20px;height: 20px;">
                    <span style="margin-left: 10px;flex: 1">{{item.awardName}}</span>
                    <span style="margin-left: 10px;width: 100px;overflow: hidden">{{item.nickname}}</span>
                </div>
            </div>
        </van-dialog>

        <van-dialog v-model="showGbInput" show-cancel-button title="发送全服广播" confirm-button-text="发送" :before-close="sendGb">
            <div style="display: flex;flex-direction: column;align-items: flex-start;font-size: 12px;color: #888888;padding: 5px 15px;">
                <span> · 发送的广播消息全天使服在线玩家都能收到</span>
                <span> · 每次发送广播消息将消耗5个七里币</span>
                <span style="margin-top: 10px;">   七里币余额: {{qlb}}</span>
                <span style="margin-top: 10px;">   剩余广播次数: {{shoutNum}}</span>
            </div>
            <div style="width: 100%;font-size: 14px;padding: 10px 0;">
                <van-field label="内容"  show-word-limit @blur="campaignNameBlur" autosize type="textarea"  label-width="70px" maxlength="50" placeholder="请输入全服喇叭内容" v-model="gbMessage">
                    <template #button>
                        <!--<van-popover v-model="showEmoji" trigger="click" placement="bottom-end" :offset="emojiPanelOffset">
                            <myemoji style="width: calc(100vw - 50px)" @selectEmoji="handleEmoji"></myemoji>
                            <template #reference>
                                <span style="font-size: 22px;">😀</span>
                            </template>
                        </van-popover>-->
                        <span style="font-size: 22px;" @click="showEmoji = !showEmoji">😀</span>
                    </template>
                </van-field>
            </div>
        </van-dialog>

        <van-popup v-model="showEmoji" position="bottom" :style="{ width: '100%',height: '35%'}" >
            <myemoji  @selectEmoji="handleEmoji"></myemoji>
        </van-popup>

        <div v-if="iOSCopyButtonShow || transformGameButtonShow" style="padding: 8px 15px; background-color: #ffffff;border-radius: 5px; position: absolute;top: 260px;
                left: 0;z-index: 99;display: flex;flex-direction: column;align-items: flex-start;border: 1px solid #eeeeee;box-shadow: 2px 2px 10px 3px rgba(0,0,0,.1);">

            <div style="font-size: 12px;">当前通行证: {{codeValue}}</div>
            <div v-if="iOSCopyButtonShow" @click="doCopyCode" style="color: #ffffff;background-color: #0f78e8;border-radius: 5px;width: 90px;font-size: 12px;padding: 8px 10px;margin-top: 15px;">
                复制通行证
            </div>
            <div v-if="transformGameButtonShow"  @click="transformGamePage"  style="color: #ffffff;padding: 8px 10px;width: 90px;font-size: 12px; background-color: #f13a3a;border-radius: 5px;margin-top: 15px; ">
                <span style="color: #ffffff">通行证进入游戏</span>
            </div>
            <div @click="txzTipsShow = true" style="color: #0f78e8;width: 90px;font-size: 12px;padding: 8px 10px;margin-top: 5px;">
                加速器使用教程
            </div>
        </div>

        <div v-if="codePageShow" ref="codePageRef" style="width: 50%;height: 260px;position: absolute;top: 0;left: 0;" >
            <iframe v-show="true" allowfullscreen ref="codePageIframe" id="codePageIframe" :src="codePageUrl" frameborder="0" scrolling="no" width="100%"
                    height="100%">
            </iframe>
        </div>

        <van-popup  v-model="txzTipsShow" v-if="txzTipsShow" position="center" :style="{ width: '90%'}" style="background: transparent;max-width: 350px;">
            <!--<div v-if="isIOS" style="font-size: 12px;text-align: left;padding: 0 15px;display: flex;flex-direction: column;align-items: flex-start;">
                <span style="margin-top: 5px;">1. 点击浮窗页面中的“复制通行证”按钮，将通行证复制到剪贴板</span>
                <span style="margin-top: 5px;">2. 点击游戏右上角“通行证”按钮，打开通行证输入窗口</span>
                <span style="margin-top: 5px;">3. 将复制好的通行证粘贴到输入框，点击确认</span>
                <span style="margin-top: 5px;">4. 此时下方通行证页面已进入区服选择页面，点击浮窗页面“通行证进入游戏”按钮</span>
                <span style="margin-top: 5px;">5. 此时区服选择页面已经全屏，正常进入游戏即可</span>
            </div>
            <div v-else style="font-size: 12px;text-align: left;padding: 0 15px;display: flex;flex-direction: column;align-items: flex-start;">
                <span style="margin-top: 5px;">1. 点击游戏右上角“通行证”按钮，打开通行证输入窗口</span>
                <span style="margin-top: 5px;">2. 将系统自动复制好的通行证粘贴到输入框，点击确认</span>
                <span style="margin-top: 5px;">3. 此时下方通行证页面已进入区服选择页面，点击浮窗页面中的“通行证进入游戏”按钮</span>
                <span style="margin-top: 5px;">4. 此时区服选择页面已经全屏，正常进入游戏即可</span>
            </div>-->

            <video src="https://cdn.7li.club/pc/jsjc.mp4"  style="width: 100%;border-radius: 8px;" :controls="true" :autoplay="true" ></video>
            <div @click="txzTipsShow = false" style="font-size: 14px;font-weight: bold; height: 40px;line-height: 40px;background: #ef581b;color: #ffffff;border-radius: 40px;padding: 0 20px;width: 120px;margin: 0 auto;">
                我学会了
            </div>
        </van-popup>

    </div>
</template>

<script>
    import Vue from 'vue';
    import { Popover } from 'vant';
    Vue.use(Popover);

    import {request} from '@/api/request';
    import {Toast} from 'vant';
    import {Dialog} from 'vant';
    import myloading from '@/components/myloading';
    import passwordPage from '@/components/password';
    import bindtelPage from '@/components/bindtel';
    import unbindtelPage from '@/components/unbindtel';
    import giftdetailPage from '@/components/giftdetail';
    import giftrecordPage from '@/components/giftrecord';
    import redpackPage from '@/components/redpack';
    import myemoji from '@/components/myemoji';
    import timerhook from '@/utils/timerhook';
    import gcGame from '@/page/temp/TempGame.vue';
    import TempGame from "@/page/temp/TempGame.vue";
    
    export default {
        name: "game",
        components: {
            TempGame,
            myloading,
            passwordPage,
            bindtelPage,
            unbindtelPage,
            giftdetailPage,
            giftrecordPage,
            redpackPage,
            myemoji
        },
        data() {
           return {
               passwordShow: false,
               bindtelShow: false,
               unbindtelShow: false,
               giftdetailShow: false,
               giftrecordShow: false,
               redpackShow: false,
               showGbInput: false,
               showLoading: false,

               redIcon : 1,
               gameUrl : '',
               showAll: true,
               hasRed: false,
               showMainDialog: false,
               welfareCode: '',
               bottomMsg: '',
               page: 0,
               openHzStep: 1,
               mhNotice: '',
               userInfo: {},
               redDataList: [],
               redLogList: [],
               isBindWx: 0,
               bindWxCode: '',
               QQList: [],
               wxList: [],
               unbindCode: '',
               showCodeForUnbindWx: false,
               unbindWxTitle: '',
               unbindWxSendTime: 0,
               mhCount: 0,
               mhRecordList: [],
               isApp: '',
               isAndroid: '',

               showPayDialog: false,
               qlb: 0,
               shoutNum: 0,
               goodsName: '',
               preferenceMoney: '无',
               money: '',
               orderMoney: '',
               uid: '',
               payType: '',
               rechargePayType: '',
               loading: false,
               channelId: '',
               gameId: '',
               order: {},
               showDiscount: false,
               payUrl: '',
               payConfirmShow: false,
               payIframeShow: false,
               heartbeat: 0,
               hideAllIconTimt: 10000,
               iconTopNum: 70,
               iconTop: '70%',

               flags: false,
               position: {x: 0, y: 0},
               nx: '', ny: '', dx: '', dy: '', xPum: '', yPum: '',
               isLogout: false,
               mainStyle: '',

               showMhResult: false,
               showMhBackground: false,
               openMhMsg: '',
               openMhImg: '',
               showMhDj: false,
               mhdjList: [],
               showNotice: false,
               gbMessage: '',
               showEmoji: false,
               campaignNameIndex: 0,
               emojiPanelOffset: [20,8],
               noticeMessage: '',
               shoutId: 0,
               shoutMsgList: [],
               noticeShowTime: 0,
               noticeStyle: '',
               messageList: [],
               speedRate: 1,
               redMenuRemind: false,
               amount: '',
               gcGameShow: false,

               codePageShow: false,
               codePageUrl: '',
               codeValue: '',
               iOSCopyButtonShow: false,
               transformGameButtonShow: false,
               txzTipsShow: false,
               isIOS: false,
           }
        },
        mounted() {
            console.log('......主页面初始化');
            localStorage.removeItem('login_status');
            let topNum = localStorage.getItem('iconTopNum');
            if (!!topNum) {
                this.iconTopNum = Number.parseInt(topNum);
                this.iconTop = topNum + '%';
            }
            this.isApp = localStorage.getItem('isApp');
            this.isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
            let systemInfo = JSON.parse(localStorage.getItem('systemInfo'));
            this.channelId = systemInfo.channelId;
            console.log('channelId', this.channelId);
            this.gameUrl = this.userInfo.gameUrl;
            //this.gameUrl = 'https://cps.7li.cn/#/login';
            this.uid = this.userInfo.userId;
            this.bottomMsg = this.userInfo.flMsg;
            window.addEventListener("message", this.listener);
            this.checkHasRed();
            this.showShoutMsg();
            setTimeout(() => {
                this.redIcon = 2;
            }, this.hideAllIconTimt);
            this.sendKeyboardFlag(true);

            this.isIOS = this.getIsIOS();
            this.codePageUrl = localStorage.getItem('codePageUrl');
            if (this.codePageUrl) {
                this.codePageShow = true;
                localStorage.removeItem('codePageUrl');
                if (this.isIOS) {
                    setTimeout(() => {
                        this.getAndCopyCode() ;
                        this.iOSCopyButtonShow = true;
                    }, 2000)
                }
                else {
                    setTimeout(() => {
                        this.getAndCopyCode() ;
                        Toast('通行证复制成功');
                        this.transformGameButtonShow = true;
                    }, 2000)
                }
            }
        },
        beforeDestroy() {
           window.removeEventListener("message", this.listener);
        },
        destroy() {

        },
        methods: {
            transformGamePage() {
                this.$refs.codePageRef.style.height = "100vh";
                this.$refs.codePageRef.style.width = "100vw";
                this.transformGameButtonShow = false;
            },
            doCopyCode() {
                this.copyCodeForIOS(this.codeValue);
                Toast('通行证复制成功');
                setTimeout(() => {
                    this.iOSCopyButtonShow = false;
                    this.transformGameButtonShow = true;
                }, 1000)
            },
            copyCodeForIOS(value, type = 'input') {
                const input = document.createElement(type);
                input.setAttribute('readonly', 'readonly'); // 设置为只读, 防止在 ios 下拉起键盘
                input.value = value;
                document.body.appendChild(input);
                input.setSelectionRange(0, 9999); // 防止 ios 下没有全选内容而无法复制
                input.select();
                document.execCommand('copy');
                document.body.removeChild(input);
            },
            copy37Code(code) {
                const input = document.createElement('input')
                document.body.appendChild(input)
                input.setAttribute('value', code)
                input.select()
                if (document.execCommand('copy')) {
                    document.execCommand('copy')
                }
                document.body.removeChild(input);
                Toast('通行证复制成功');
            },
            getAndCopyCode() {
                try {
                    const iframe = document.getElementById('codePageIframe');
                    const doc = iframe.contentDocument || iframe.contentWindow.document;
                    let div = doc.getElementById("qrcode");
                    if (!!div) {
                        this.codeValue = div.title;
                        console.log('codeValue', this.codeValue);
                        if(!this.isIOS) {
                            this.copy37Code(this.codeValue);
                        }
                    }
                    else {
                        this.codeValue = '';
                    }
                } catch (e) {
                    console.log('exception', e)
                    this.codeValue = '';
                }
            },
            getIsIOS() {
                return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 检测是否为ios
            },
            inputFocus() {
                document.getElementById('mainView').scrollTo(0,1000)
            },
            setSpeed(n) {
                this.speedRate = n;
                timerhook.setSpeed(n);
                Toast('游戏当前加速倍率：' + n);
            },
            inputBlur() {
            },
            checkHasRed() {
                let options = {
                    url:"/zfapp/heartBeat2",
                    data: {
                        shoutId: this.shoutId
                    }
                };
                request(options).then(res => {
                    let c = false;
                    if (res.data.success) {
                        let has = res.data.data.redStatus;
                        this.hasRed = has === '1';
                        if (this.hasRed  && this.redIcon === 2) {
                            this.redIcon = 3;
                        }

                        let msgList = res.data.data.shoutMsgList;
                        for (let i = 0; i < msgList.length; i++) {
                            let msg = msgList[i];
                            this.shoutId = msg.id;
                            this.shoutMsgList.push(msg);
                        }
                       c = true;
                    }
                    else {
                        console.log(res.data.error.message);
                        if (res.data.error.code !== '911') {
                            c = true;
                        }
                    }

                    if (c) {
                        setTimeout(() => {
                            if (!this.isLogout) {
                                this.checkHasRed();
                            }
                        }, 5000);
                    }

                });
            },
            refreshUserInfo() {
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
            },
            openMain() {
                this.refreshBalance();
                this.showMainDialog = !this.showMainDialog;
            },
            sendKeyboardFlag(flag) {
                this.sendUniMessage('uniKeyboardFlag', flag);
            },
            showAllIcon() {
                this.redIcon = 1;
                setTimeout(() => {
                    this.redIcon = 2;
                }, this.hideAllIconTimt);
            },
            goPasswordPage() {
                this.passwordShow = true;
                this.sendKeyboardFlag(false);
            },
            colsePasswordPage() {
                this.passwordShow = false;
                this.sendKeyboardFlag(true);
            },
            goUnBindPage() {
                this.unbindtelShow = true;
                this.sendKeyboardFlag(false);
            },
            closeUnBindPage() {
                this.refreshUserInfo();
                this.unbindtelShow = false;
                this.sendKeyboardFlag(true);
            },
            goBindPage() {
                this.bindtelShow = true;
                this.sendKeyboardFlag(false);
            },
            closeBindPage() {
                this.refreshUserInfo();
                this.bindtelShow = false;
                this.sendKeyboardFlag(true);
            },
            toGiftDetailPage() {
                this.giftdetailShow = true;
                this.$nextTick(function () {
                    this.$refs.gitDetailRef.init();
                });

            },
            closeGiftDetailPage() {
                this.giftdetailShow = false;
            },
            toGiftRecordPage() {
                this.giftdetailShow = false;
                this.giftrecordShow = true;
                this.$nextTick(function () {
                    this.$refs.gitRecordRef.init();
                });

            },
            closeGiftRecordPage() {
                this.giftdetailShow = true;
                this.giftrecordShow = false;
            },
            toRedPage() {
                this.page = 1;
                this.showLoading = true;

                if (this.redMenuRemind) {
                    let dateStr = this.getDateStr()
                    localStorage.setItem('click_red_date', dateStr);
                    this.redMenuRemind = false;
                }

                let options = {
                    url:"/zfapp/getRedList",
                    data: {}
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.redDataList = res.data.data;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            toBingWxPage() {
                this.isBindWx = 0;
                this.showLoading = true;
                let options = {
                    url:"/zfapp/bindWx",
                    data: {}
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let msg = res.data.data;
                        if (msg.indexOf('已绑定') > -1) {
                            this.isBindWx = 1;
                        }
                        else {
                            this.copyCode(msg);
                        }
                        this.bindWxCode = msg;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
                this.page = 3;
            },
            toGcGamePage(){
                this.gcGameShow = true;
                this.$nextTick(function (){
                    this.$refs.gcGame.createIframe();
                });
            },
            closeGcGame(){
                this.gcGameShow = false;
                this.sendKeyboardFlag(true);
            },
            logout() {
                Dialog.confirm({
                    message: '确认要退出游戏吗?',
                })
                .then(() => {
                    this.showLoading = true;
                    let options = {
                        url:"/zfapp/logout",
                        data: {}
                    };
                    request(options).then(res => {
                        this.showLoading = false;
                        if (res.data.success) {
                            this.isLogout = true;
                            localStorage.removeItem('userInfo');
                            localStorage.removeItem('token');
                            localStorage.removeItem('userId');
                            let from = localStorage.getItem('from');
                            localStorage.setItem('login_status', 'logout');
                            if (from === 'qiliApp') {
                                this.sendUniMessage('back', '');
                            }
                            else {
                                this.$router.replace("/login");
                            }
                        }
                        else {
                            Toast(res.data.error.message);
                        }
                    });
                })
                .catch(() => {
                });
            },
            submitRecharge() {
                if (!this.amount) {
                    Toast('请输入充值金额');
                    return;
                }
                if (!this.rechargePayType) {
                    Toast('请选择支付方式');
                    return;
                }
                if (this.isApp === '2' && this.rechargePayType === '3') {
                    let wxPay = localStorage.getItem('wxPay');
                    if (wxPay !== 'h5') {
                        this.rechargePayType = '7';
                    }
                }

                this.showLoading = true;
                let options = {
                    url:"/zfapp/qlOrder",
                    data: {
                        money: this.amount,
                        paymentType: this.rechargePayType
                    }
                };

                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let payString = res.data.data.payString;
                        //处理ios端七里app的支付
                        let from = localStorage.getItem('from');
                        if (from === 'qiliApp') {
                            this.sendUniMessage('continue', payString);
                        }
                        else if (this.isApp === '2') {
                            //拉起微信app支付
                            if (this.rechargePayType === '7') {
                                window.tsJsInterface.openWxAppPay(payString);
                            }
                            else {
                                window.tsJsInterface.openUrl(payString);
                            }

                        }
                        else {
                            this.payUrl = payString;
                            this.payConfirmShow = true;
                        }
                        this.showMainDialog = false;
                        this.page = 0;
                        this.rechargePayType = '';
                        this.amount = '';
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            toMhPage() {
                this.openHzStep = 1;
                this.showLoading = true;
                let options = {
                    url:"/zfapp/getOpenNum",
                    data: {}
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.mhCount = res.data.data;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
                this.page = 4;
                let noticeOptions = {
                    url:"/zfapp/blindBoxBest",
                    data: {}
                };
                request(noticeOptions).then(res => {
                    if (res.data.success) {
                        this.mhNotice = res.data.data;
                    }
                });
            },
            openHz() {
                if (this.mhCount <= 0) {
                    Toast('盲盒数量不够啦');
                    return ;
                }
                this.openHzStep = 2;
                let start = new Date().getTime();
                let options = {
                    url:"/zfapp/openBlindBox2",
                    data: {}
                };
                request(options).then(res => {
                    if (res.data.success) {
                        this.mhCount --;
                        let end = new Date().getTime();
                        if (end - start < 1000) {
                            setTimeout(() => {
                                this.openHzStep = 3;
                                this.showMhResult = true;
                                this.openMhMsg = res.data.data.awardName;
                                this.openMhImg = res.data.data.imgUrl;

                                if (res.data.data.awardType === 1) {
                                    this.showMhBackground = true;
                                    setTimeout(() => {
                                        this.showMhBackground = false;
                                    }, 5000)
                                }
                            }, 1000);
                        }
                        else {
                            this.openHzStep = 3;
                            this.showMhResult = true;
                            this.openMhMsg = res.data.data.awardName;
                            this.openMhImg = res.data.data.imgUrl;

                            if (res.data.data.awardType === 1) {
                                this.showMhBackground = true;
                                setTimeout(() => {
                                    this.showMhBackground = false;
                                }, 5000)
                            }
                        }
                    }
                    else {
                        this.openHzStep = 1;
                        Toast(res.data.error.message);
                    }
                });
            },
            closeMhResultDialog() {
                if (this.mhCount > 0) {
                    this.openHzStep = 1;
                }
            },
            toKfPage() {
                this.showLoading = true;
                let options = {
                    url:"/zfapp/getConfig",
                    data: {}
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.QQList = res.data.data.qqList;
                        this.wxList = res.data.data.wxList;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
                this.page = 5;
            },
            toSpeedPage() {
                this.page = 6;
            },
            toRedRecordPage() {
                this.page = 11;
                this.showLoading = true;
                let options = {
                    url:"/zfapp/redLogList",
                    data: { }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.redLogList = res.data.data;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            backMain() {
                this.page = 0;
            },
            toWxForBind() {
                let url = 'weixin://';
                if (this.isApp === '1') {
                    this.sendUniMessage('openUrl', url);
                }
                else if (this.isApp === '2') {
                    window.tsJsInterface.startIntent(url);
                }
                else {
                    window.open('weixin://', '_blank');
                }
            },
            toMhRecordPage() {
                this.showLoading = true;
                let options = {
                    url:"/zfapp/blindBoxList",
                    data: {}
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.mhRecordList = res.data.data;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
                this.page = 12;
            },
            unBindWx() {
                Dialog.confirm({
                    message: '确认要解绑微信吗?',
                    messageAlign: 'left'
                })
                .then(() => {
                    this.checkHasBindPhone();
                })
                .catch(() => {
                });
            },
            checkHasBindPhone() {
                if(!!this.userInfo.phone) {
                    let now = new Date().getTime();
                    let interval = (now - this.unbindWxSendTime) /1000;
                    if (interval < 60) {
                        Toast('操作太频繁，请在' + (60 - interval) + '秒后重试');
                        return ;
                    }

                    this.showLoading = true;
                    let options = {
                        url:"/zfapp/sendSms",
                        data: {
                            type: 6
                        }
                    };
                    request(options).then(res => {
                        this.showLoading = false;
                        if (res.data.success) {
                            this.unbindWxSendTime = now;
                            this.showCodeForUnbindWx = true;
                            this.unbindWxTitle = '验证码已发送至您的手机' + this.userInfo.phone + '，请注意查收';
                        }
                        else {
                            Toast(res.data.error.message);
                        }
                    });
                }
                else {
                    this.doUnbindWx();
                }
            },
            handleEmoji(e) {
                if (this.gbMessage.length + e.length > 50) {
                    return ;
                }
                if (this.gbMessage) {
                    let index = this.campaignNameIndex;
                    let name = this.gbMessage;
                    this.gbMessage = name.slice(0,index) + e + name.slice(index);
                }
                else {
                    this.gbMessage = e;
                }
                console.log(e.length);
                this.campaignNameIndex = this.campaignNameIndex + e.length;
                this.showEmoji = false;
            },
            campaignNameBlur(e){//记录光标位置
                this.campaignNameIndex = e.srcElement.selectionStart;
            },
            hideNotice() {
                setTimeout(() => {
                    this.showNotice = false;
                    this.showShoutMsg();
                }, 1000)
            },
            showShoutMsg() {
                if (this.shoutMsgList.length > 0) {
                    let msg = this.shoutMsgList[0];
                    if (this.shoutMsgList.length > 1) {
                        this.shoutMsgList = this.shoutMsgList.slice(1);
                    }
                    else {
                        this.shoutMsgList = [];
                    }

                    let msgResult = '';
                    if (msg.msgType === 1) {
                        this.noticeStyle = '';
                        msgResult = `[${msg.serverName}] ${msg.roleName} ：${msg.msg}`;
                    }
                    else if (msg.msgType === 2) {
                        this.noticeStyle = 'font-weight: bold; color: rgb(58 248 5); ';
                        msgResult = `官方通知： ${msg.msg}`;
                    }
                    else {
                        msgResult = `${msg.msg}`;
                    }

                    this.noticeMessage = '                                                                                                    ' + msgResult;
                    this.noticeShowTime = new Date().getTime();
                    this.showNotice = true;
                    console.log('开始播放时间', this.noticeShowTime)
                }
                else {
                    setTimeout(() => {
                        this.showShoutMsg();
                    }, 3000);
                }
            },
            sendGbNew() {
                if (!this.gbMessage) {
                    Toast("请输入广播内容");
                    return ;
                }
                this.showLoading = true;
                let options = {
                    url:"/zfapp/shout",
                    data: {
                        msg: this.gbMessage
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('发送成功');
                        this.gbMessage = '';
                        this.queryMessageList();
                        this.refreshNewBalance();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            sendGb(action, done) {
                if (action === 'confirm') {
                    if (!this.gbMessage) {
                        Toast("请输入广播内容");
                        return done(false);
                    }
                    let options = {
                        url:"/zfapp/shout",
                        data: {
                            msg: this.gbMessage
                        }
                    };
                    request(options).then(res => {
                        if (res.data.success) {
                            Toast('发送成功');
                            this.gbMessage = '';
                            return done(true);
                        }
                        else {
                            Toast(res.data.error.message);
                            return done(false);
                        }
                    });
                }
                else {
                    return done(true);
                }
            },
            doUnbindWxWithCode(action, done) {
                if (action === 'confirm') {
                    if (!/^\d{6}$/.test(this.unbindCode)) {
                        Toast("验证码为6位数字");
                        return done(false);
                    }
                    this.showLoading = true;
                    let options = {
                        url:"/zfapp/unBindWx",
                        data: {
                            checkCode: this.unbindCode
                        }
                    };
                    request(options).then(res => {
                        this.showLoading = false;
                        if (res.data.success) {
                            Toast('微信解绑成功');
                            this.backMain();
                            return done(true);
                        }
                        else {
                            Toast(res.data.error.message);
                            return done(false);
                        }
                    });
                }
                else {
                    return done(true);
                }
            },
            doUnbindWx() {
                this.showLoading = true;
                let options = {
                    url:"/zfapp/unBindWx",
                    data: {
                        checkCode: ''
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('微信解绑成功');
                        this.backMain();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            toQQKf(e) {
                let url = 'mqq://im/chat?chat_type=wpa&uin=' + e + '&version=1&src_type=web';
                if (this.isApp === '1') {
                    this.sendUniMessage('openUrl', url);
                }
                else if (this.isApp === '2') {
                    window.tsJsInterface.startIntent(url);
                }
                else {
                    window.open('mqq://im/chat?chat_type=wpa&uin=' + e + '&version=1&src_type=web', '_blank');
                }
            },
            toWxKf(e) {
                if (this.isApp === '1') {
                    this.sendUniMessage('openUrl', e);
                }
                else if (this.isApp === '2') {
                    window.tsJsInterface.openUrl(e);
                }
                else {
                    window.open(e, '_blank');
                }
            },
            openRedpack(item) {
                this.showLoading = true;
                let options = {
                    url:"/zfapp/openRed",
                    data: {
                        redId: item.redId
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.redpackShow = true;
                        this.$nextTick(function () {
                            this.$refs.redpackRef.queryData(item.redId);
                        });
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            closeRedpackPage() {
                this.redpackShow = false;
            },
            copyCode(v) {
                if (this.isApp === '1') {
                    this.sendUniMessage('uniCopy', v);
                }
                else if (this.isApp === '2') {
                    window.tsJsInterface.copyString(v);
                }
                else {
                    const input = document.createElement('input')
                    document.body.appendChild(input)
                    input.setAttribute('value', v)
                    input.select()
                    if (document.execCommand('copy')) {
                        document.execCommand('copy')
                    }
                    document.body.removeChild(input)
                }

            },
            openTradeDialog() {
                Dialog.confirm({
                    title : '交易平台',
                    message : '如需使用天使服专属交易平台，请前往“七里网络”公众号，点击：“服务-交易平台”',
                    messageAlign: 'left',
                    confirmButtonText: '前往微信'
                }).then(()=>{
                    this.toWxForBind();
                }).catch(()=>{})
            },
            openScDialog() {
                Dialog.confirm({
                    title : '天使服素材有奖征集',
                    message : '现已开放天使服素材有奖征集活动，请前往“七里网络”公众号，点击：“在线客服-素材有奖征集”',
                    messageAlign: 'left',
                    confirmButtonText: '前往微信'
                }).then(()=>{
                    this.toWxForBind();
                }).catch(()=>{})
            },
            openGbDialog() {
                this.refreshNewBalance();
                //this.showGbInput = true;
                this.page = 13;
                setTimeout(() => {
                    //document.getElementById("inputText").focus();
                    this.addInputBlur();
                }, 100);
                this.queryMessageList();
            },
            openRecharge() {
                this.refreshNewBalance();
                this.page = 14;
                setTimeout(() => {
                    //document.getElementById("inputText").focus();
                    this.addInputBlur();
                }, 100);
            },
            queryMessageList() {
                this.showLoading = true;
                let options = {
                    url:"/zfapp/shoutLogList",
                    data: {}
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        console.log(res.data.data);
                        this.messageList = res.data.data;
                        setTimeout(function() {
                            const scrollHeight = document.getElementById("msgListView").scrollHeight;
                            document.getElementById("msgListView").scroll(0, scrollHeight);
                        }, 100)
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            showMhInfo() {
                Dialog.alert({
                    title : '盲盒奖励',
                    message :
                        'iPhone13  华为P50\n' +
                        '七里币 888  188  68 等\n' +
                        '微信红包188.88  66.66  18.88 等\n\n' +
                        '最终解释权归七里平台所有，详情请咨询在线客服',
                    messageAlign: 'left'
                })
            },
            showDjList() {
                this.showMhDj = true;
                let options = {
                    url:"/zfapp/blindBoxBigList",
                    data: {}
                };
                request(options).then(res => {
                    if (res.data.success) {
                       console.log(res.data.data);
                       this.mhdjList = res.data.data;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            welfareCodeSubmit() {
                if (this.welfareCode.length === 0) {
                    Toast('请输入七里福利码');
                    return;
                }
                this.showLoading = true;
                let options = {
                    url:"/zfapp/welfareGet",
                    data: {
                        welfareCode: this.welfareCode
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.welfareCode = '';
                        Toast('礼包码兑换成功');
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            changePayType() {},
            listener(event) {
                console.log('收到游戏通知:', event);
                if (event.data.operation === 'pay') {
                    console.log("触发支付");
                    let order = event.data.param;
                    if (this.isApp === '1') {
                        this.sendUniMessagePay(order);
                    }
                    else {
                        this.openPayPage(order);
                    }
                }
                else if (event.data.operation === 'uploadGameRole') {
                    console.log("触发角色上报");
                    let param = event.data.param;
                    this.uploadRole(param);
                }
                else if (event.data.operation === 'brGameRole') {//开启加速版本角色上报
                    console.log("js触发角色上报");
                    let param = event.data.roleParam;
                    this.uploadRole(param);
                }
                else if (event.data.operation === 'repeatLogin') {
                    console.log("触发登陆被顶");
                    this.repeatLogin();
                }
            },
            repeatLogin() {
                localStorage.removeItem('userInfo');
                localStorage.removeItem('token');
                localStorage.removeItem('userId');
                this.$router.replace("/login");
            },
            openPayPage(order) {
                this.goodsName = order.goodsName;
                this.orderMoney = "￥" + (order.money / 100).toFixed(2);
                this.money = order.money;
                this.order = order;

                this.showLoading = true;
                let options = {
                    url:"/zfapp/checkUser",
                    data: {
                        cpOrder: order.cpOrderId,
                        goodsName: this.goodsName,
                        money: this.money,
                        serviceId: order.server,
                        desc: this.goodsName,
                        roleName: order.role,
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.showPayDialog = true;
                        if (res.data.data.discount !== "1") {
                            this.showDiscount = true;
                            this.preferenceMoney = "- ￥" + res.data.data.preferenceMoney + ' / ' + res.data.data
                                .discount;
                        } else {
                            this.showDiscount = false;
                            this.preferenceMoney = "无"
                        }
                        this.orderMoney = "￥" + res.data.data.orderMoney;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });

                let getQlbOptions = {
                    url:"/zfapp/getGameQLBalance",
                    data: {}
                };
                request(getQlbOptions).then(res => {
                    if (res.data.success) {
                        this.qlb = res.data.data;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            addInputBlur() {
                let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // 检测是否为ios
                if(!isIOS) {
                    console.log('不是iOS，取消blur事件');
                    return ;
                }
                let t = document.getElementById('inputText');
                console.log(t)
                t.removeEventListener('blur', function(){ window.scrollTo(0,0)});
                t.addEventListener('blur', function(){window.scrollTo(0,0);}, false)
            },
            refreshNewBalance() {
                let getQlbOptions = {
                    url:"/zfapp/getUserBalanceList",
                    data: {}
                };
                request(getQlbOptions).then(res => {
                    if (res.data.success) {
                        this.qlb = res.data.data.qlBalance;
                        this.shoutNum = res.data.data.shoutNum;
                    }
                });
            },
            refreshBalance() {
                let getQlbOptions = {
                    url:"/zfapp/getMainFloatData",
                    data: {}
                };
                request(getQlbOptions).then(res => {
                    if (res.data.success) {
                        this.qlb = res.data.data.qlb;
                        let hasRed = res.data.data.hasRedPack;
                        let dateStr = this.getDateStr()
                        console.log('dateStr', dateStr);
                        if (hasRed === '1') {//有红包，判断是否已经点击菜单进入页面
                            let clickRedDate = localStorage.getItem('click_red_date')
                            if (clickRedDate !== dateStr) {
                                this.redMenuRemind = true;
                            }
                        }
                    }
                });
            },
            getDateStr() {
                let myDate = new Date();
                return myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + '-' + myDate.getDate()
            },
            uploadRole(roleParam) {
                let options = {
                    url:"/zfapp/roleSync",
                    data: {
                        remark: roleParam.remark,
                        roleId: roleParam.roleId,
                        roleLevel: "",
                        roleName: roleParam.roleName,
                        serviceId: roleParam.serverId,
                        serviceName: roleParam.serverName,
                    }
                };
                request(options).then(res => {
                    if (res.data.success) {
                        console.log('角色信息上报成功');
                    }
                    else {
                        console.log('角色信息上报失败: ' + res.data.error.message);
                    }
                });
            },
            pay() {
                if (!this.payType) {
                    Toast('请选择支付方式');
                    return ;
                }
                this.showLoading = true;

                //如果是加速版app微信支付就指定为app支付
                if (this.isApp === '2' && this.payType === '3') {
                    let wxPay = localStorage.getItem('wxPay');
                    if (wxPay !== 'h5') {
                        this.payType = '7';
                    }
                }

                let options = {
                    url:"/zfapp/preOrder",
                    data: {
                        cpOrder: this.order.cpOrderId,
                        goodsName: this.goodsName,
                        money: this.money,
                        paymentType: this.payType,
                        serviceId: this.order.server,
                        desc: this.goodsName,
                        roleName: this.order.role,
                        roleId: this.order.role,
                        orderTime: this.order.time,
                        sign: this.order.sign
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        if (res.data.data.paymentType !== "1") {//如果是七里币支付的话就不需要打开链接了
                            this.showPayDialog = false;
                            //处理ios端七里app的支付
                            let from = localStorage.getItem('from');
                            if (from === 'qiliApp') {
                                this.sendUniMessage('continue', res.data.data.payString);
                            }
                            else if (this.isApp === '2') {
                                //拉起微信app支付
                                if (this.payType === '7') {
                                    window.tsJsInterface.openWxAppPay(res.data.data.payString);
                                }
                                else {
                                    window.tsJsInterface.openUrl(res.data.data.payString);
                                }

                            }
                            else {
                                this.payUrl = res.data.data.payString;
                                this.payConfirmShow = true;
                            }
                        }
                        else {
                            this.showPayDialog = false;
                            Toast('支付成功');
                        }
                    }
                    else {
                        this.showPayDialog = false;
                        Toast(res.data.error.message);
                    }
                });

            },
            sendUniMessage(type, m) {
                uni.postMessage({
                    data: {
                        action: type,
                        msg: m
                    }
                });
            },
            sendUniMessagePay(order) {
                let isAppPay = 1;
                /*if (this.channelId === '179') {
                    isAppPay = 0;
                }*/
                uni.postMessage({
                    data: {
                        action: 'uniPay',
                        msg: order,
                        isAppPay: isAppPay,//安卓端是否开启微信app支付功能
                        token: localStorage.getItem('token')
                    }
                });
            },
            down(event) {
                this.flags = true;
                let touch;
                if (event.touches) {
                    touch = event.touches[0];
                } else {
                    touch = event;
                }
                this.position.x = touch.clientX;
                this.position.y = touch.clientY;
                this.dx = moveDiv.offsetLeft;
                this.dy = moveDiv.offsetTop;
            },
            move(event) {
                if (this.flags) {
                    let touch;
                    if (event.touches) {
                        touch = event.touches[0];
                    } else {
                        touch = event;
                    }
                    this.nx = touch.clientX - this.position.x;
                    this.ny = touch.clientY - this.position.y;
                    this.xPum = this.dx + this.nx;
                    this.yPum = this.dy + this.ny;
                    moveDiv.style.left = this.xPum + "px";
                    moveDiv.style.top = this.yPum + "px";
                    //阻止页面的滑动默认事件；如果碰到滑动问题，1.2 请注意是否获取到 touchmove
                    document.addEventListener("touchmove", function () {
                        event.preventDefault();
                    }, false);
                }
            },
            //鼠标释放时候的函数
            end(event) {
                this.flags = false;
                if (!this.xPum) {
                    return;
                }
                if (this.yPum < 0) {
                    moveDiv.style.top = 0;
                }

                //console.log( this.yPum );

                let {
                    clientHeight: windowHeight,
                    clientWidth: windowWidth
                } = document.documentElement;

                if (this.yPum + 70 > windowHeight) {
                    console.log( this.yPum + 70 - windowHeight )
                    moveDiv.style.bottom = 0;
                    moveDiv.style.top = 'auto';
                }

                //console.log( windowHeight );
                moveDiv.style.left = 0;
                moveDiv.style.right = 'auto';
            },
            start() {

            },
        }
    }
</script>

<style scoped>
    .main {
        background-color: #ffffff;
        width: 100vw;
        height: 100vh;
        padding: 0;
    }
    .red-remind {
        animation: zoomInOut .3s infinite alternate;
    }
    @keyframes zoomInOut {
        0% { transform: scale(1); } /* 起始状态为原始大小 */
        100% { transform: scale(1.2); } /* 结束状态返回原始大小 */
    }
    .main-dialog-view{
        background-image: url(../../static/main_page/background.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
    .main-menu{
        width: 90px;
        height: 75px;
    }
    .back-input{
        background-image: url(../../static/main_page/input_2.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 40px;
    }
    .bottom-view{
        background-image: url(../../static/main_page/input.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: 5px;
        flex: 1;
        display: flex;
        flex-direction: row;
        padding: 5px 40px 0 20px;
        text-align: left;
    }
    .header-view {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 60px;
        margin-left: -3px;
        margin-right: -2px;
        margin-top: -2px;
        line-height: 55px;
        color: #FFFFFF;
        font-size: 16px;

    }
    .redlist-top {
        background-image: url('../../static/redlist/top.png');
    }
    .body-view {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height:100%;
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
    .redlist-body{
        background-image: url('../../static/redlist/background.png');
    }
    .mh-top{
        background-image: url('../../static/draw/top.png');
        height: 50px;
    }
    .mh-body{
        background-image: url('../../static/draw/back.png');
    }
    .left-view{
        position: absolute;
        left: 0;
        width: 40px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .redlist-content{
        background-image: url('../../static/redlist/background_main.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        flex: 1;
        width: 100%;
        padding: 15px 25px;
        box-sizing: border-box;
        text-align: left;
        overflow-y: auto;
    }
    .redlist-record-view{
        background-image: url('../../static/redlist/line-back.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 60px;
        margin-top: 5px;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        padding: 0 20px;
    }
    .pay-button{
        font-size: 12px;
        height: 30px;
        border-radius: 2px;
        margin-top: 15px;
    }
    .diy-dialog {
        background-color: #FFFFFF;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 8px 1px 8px;
        text-align: left;
        font-size: 14px;
        width: 80%;
        border-radius: 10px;
        left: 10%;
        z-index: 99999;
        top: 30%;
    }

    .diy-dialog-buttons {
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        height: 40px;
    }

    .message-input {
        font-size: 12px;
        height: 33px;
        flex: 1;
        border-radius: 3px;
    }
    .speed-button {
        font-weight: bold;
        width: 40px;
        height: 40px;
        font-size: 12px;
        line-height: 40px;
        color: #ffffff;
        background-color: rgba(240, 88, 88, 0.15);
        border: 2px solid #F05858;
        border-radius: 3px;
        text-align: center
    }

    /deep/.message-input-view  .van-cell {
        padding: 5px 10px;
    }

    /deep/.back-input .van-field__control {
        color: #FFFFFF;
    }
    input::-webkit-input-placeholder { /* WebKit browsers */
        color: #c4c3c3;
    }
    input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:#c4c3c3;
    }
    input::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:#c4c3c3;
    }
    /deep/.van-notice-bar__content{
        white-space:pre;
    }
    /deep/.van-notice-bar {
    }
    .van-cell {
        padding: 5px 10px;
    }
    .qili-icon {
        width: 60px;
        height: 70px;
        position: fixed;
        touch-action: none;
        /* 如果碰到滑动问题，1.3 请检查 z-index。z-index需比web大一级*/
        z-index: 999;
        top: 70%;
        left: 0;
        text-align: left;
    }
    .pay-item {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        padding: 5px 15px;
        border-radius: 4px;
        color: #444444;
        border: 1px solid #9e9e9e;
        background: #eeeeee;
        margin-top: 5px;
    }
</style>